import React from 'react';
import PropTypes from 'prop-types';
import { AwesomeButton } from 'react-awesome-button';
import '../../styles/Common/reach_button.scss';
import 'react-awesome-button/src/styles/styles.scss';

// do something with next()
const DefaultButton = ({
  text,
  callback,
  disabled,
  loading,
}) => (
  <AwesomeButton
    type="primary"
    size="medium"
    className="override"
    disabled={disabled}
    onPress={next => callback(next)}
  >
    {!loading ? text : <div className="donut" />}
  </AwesomeButton>
);

DefaultButton.propTypes = {
  text: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

DefaultButton.defaultProps = {
  disabled: false,
  loading: false,
};

export default DefaultButton;
